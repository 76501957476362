
import { mapGetters } from 'vuex'

import authenticate from '@auth/util'
import { BASE_META, DEFAULT_META_IMAGE, getMetas } from '@core/helpers/head'
import { CookiesModal } from '@legal/components/CookiesModal'
import Footer from '@navigation/components/Footer'
import Header from '@navigation/components/Header.vue'
import AppsflyerMixin from '@tracking/appsflyer/mixin.vue'
import BrazePrompt from '@tracking/braze/components/BrazePrompt.vue'

import translations from './default.translations'

export default {
  components: {
    BrazePrompt,
    Footer,
    Header,
    CookiesModal,
  },

  mixins: [AppsflyerMixin],

  head() {
    return {
      titleTemplate: `%s | ${this.$t(translations.companyName)}`,

      htmlAttrs: {
        lang: this.locale,
      },

      meta: [
        ...BASE_META,
        ...getMetas({
          image: this.$static(DEFAULT_META_IMAGE),
          url: `${this.baseURL}${this.$route.fullPath}`,
        }),
      ],
    }
  },

  computed: {
    ...mapGetters({
      locale: 'config/locale',
      baseURL: 'config/baseURL',
      country: 'config/country',
    }),

    shouldRenderCookiesModalClientSide() {
      return this.$config.FF_COOKIES_MODAL_RENDERED_CLIENT_SIDE_IN_COUNTRY?.includes(
        this.country,
      )
    },
  },

  beforeMount() {
    authenticate({
      store: this.$store,
      config: this.$config,
    })
  },
}
